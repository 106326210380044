import { SimpleTag, SimpleTagContainer } from "./simple-tag";

import ButtonElement from "../../shared/button-element";
import CityPhoto from "./city-photo.webp";
import Cover from "./city-device-cover.webp";
import FeaturedByApple from "./featured-by-apple";
import Grid from "../../shared/grid";
import { H1 } from "../../shared/headings";
import { Logo } from "../footer/logo";
import MaskImage from "./city-border.svg";
import React from "react";
import ScrollOffset from "../../shared/ScrollOffset";
import Section from "../../shared/section";
import Starfield from "./starfield";
import { StaticImage } from "gatsby-plugin-image";
import Video from "./video.mp4";
import VideoPoster from "./video-poster.webp";
import { motion } from "framer-motion";
import styled from "styled-components";
import useMediaQuery from "../../shared/hooks/use-media-query";

const variants = {
    down: { y: 30, opacity: 0, rotate: 14 },
    up: { y: 0, opacity: 1, rotate: 0 }
}

const delayed_variants = {
    down: { opacity: 0, y: 20 },
    up: { opacity: 1, y: 0 }
}

const glow_variants = {
    down: { opacity: 0 },
    up: { opacity: 1 }
}

const Intro = () => {
    const isLaptopLgWidth = useMediaQuery(`(min-width: 1440px)`);
    return (
        <>
        <Starfield/>
        <Section id="intro">
            <FeaturedByApple
                variants={delayed_variants}
                animate="up"
                initial="down"
                transition={{ type: 'spring', bounce: 0, duration: 1.5, delay: 1 }}
            />
            <Grid>
                <Brand
                    variants={variants}
                    animate="up"
                    initial="down"
                    transition={{ type: 'spring', bounce: 0, duration: 1.5 }}
                >
                    <Logo/>
                </Brand>
            </Grid>
            <motion.div
                variants={delayed_variants}
                animate="up"
                initial="down"
                transition={{ type: 'spring', bounce: 0, duration: 1.5, delay: 1 }}
            >
                <Grid>
                    <SimpleTagContainer>
                        <SimpleTag href="https://changelog.copilot.money/log/cash-flow">Introducing Cash Flow: Visualize your financial rhythm.</SimpleTag>
                        <ArrowIcon/>
                    </SimpleTagContainer>
                    <H1 dense>
                        The best money tracker app<Highlight>Navigate your finances with confidence. Track spending, budgets, investments, net worth.</Highlight>
                    </H1>
                    <DownloadButton>
                        <PlatformCallout>
                            <PlatformLabel>Now available for Mac and iPhone</PlatformLabel>
                        </PlatformCallout>
                        <ButtonElement to="/download">Download</ButtonElement>
                    </DownloadButton>
                </Grid>
                <Hero>
                    <CopilotUI>
                        <DesktopContainer>
                            <DesktopSurround>
                                <StaticImage
                                    src="./desktop-surround.png"
                                    width={1198.4}
                                    quality={100}
                                    placeholder="none"
                                    loading="eager"
                                    alt=""
                                />
                            </DesktopSurround>
                            <ScrollContainer>
                                <ScrollOffset
                                    scrollStart={0}
                                    scrollEnd={1200}
                                    offsetStart={40}
                                    offsetEnd={-200}
                                >
                                    <StaticImage
                                        src="./desktop-content.png"
                                        width={898}
                                        quality={100}
                                        placeholder="none"
                                        loading="eager"
                                        alt=""
                                    />
                                </ScrollOffset>
                            </ScrollContainer>
                            <DeviceGlow
                                variants={glow_variants}
                                animate="up"
                                initial="down"
                                transition={{ type: 'spring', bounce: 0, duration: 6, delay: 1.5 }}
                            />
                        </DesktopContainer>
                        <VideoContainer>
                            {/* when desktop image is shown don't use the mobile video */}
                            {isLaptopLgWidth ? (
                                <StaticImage
                                    src="./video-poster.jpg"
                                    width={285}
                                    quality={100}
                                    placeholder="none"
                                    loading="eager"
                                    alt=""
                                    style={{position:"relative",top:13}}
                                />
                            ) : (
                                <video
                                    width="285px"
                                    playsInline
                                    autoPlay
                                    muted
                                    loop
                                    poster={VideoPoster}
                                >
                                    <source src={Video} type="video/mp4" />
                                </video>
                            )}
                            <Border>
                                <StaticImage
                                    src="./video-shell-dynamic-island.png"
                                    width={315}
                                    quality={100}
                                    placeholder="none"
                                    loading="eager"
                                    alt=""
                                />
                            </Border>
                        </VideoContainer>
                    </CopilotUI>
                    {isLaptopLgWidth && <UICover/>}
                    <FrontImage>
                        <CityGlow
                            variants={glow_variants}
                            animate="up"
                            initial="down"
                            transition={{ type: 'spring', bounce: 0, duration: 6, delay: 1.5 }}
                        />
                        <MaskedImage
                            src={CityPhoto}
                            mask={MaskImage}
                        />
                    </FrontImage>
                    <CityContainer/>
                </Hero>
            </motion.div>
        </Section>
        </>
    )
}
const FrontImage = styled.div`
    width: 100%;
    height: 1200px;
    overflow: hidden;
    position: absolute;
    top: 0;
    ${props => props.theme.device.laptopLg} {
        position: relative;
    }
`
const ScrollContainer = styled.div`
    width: 895px;
    height: 645.5px;
    overflow: hidden;
    position: absolute;
    top: 71px;
    left: 275px;
`
const DesktopContainer = styled.div`
    display: none;
    ${props => props.theme.device.laptopLg} {
        position: absolute;
        top: 0;
        margin: 0 auto;
        text-align: center;
        display: block;
        width: inherit;
    }
`
const DesktopSurround = styled.div`
    position: absolute;
    top: 0;
    width: 1198.4px;
`
const DeviceGlow = styled(motion.div)`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 800px;
    height: 500px;
    background: rgba(26, 129, 255, 0.55);
    z-index: -3;
    top: 30px;
    box-shadow: 0px -40px 124px rgba(26, 129, 255, 0.3);
    border-radius: 300px;
`
const CityGlow = styled(motion.div)`
    position: absolute;
    width: 1361px;
    height: 416px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -3;
    top: 15rem;
    background: rgba(255, 214, 68, 0.4);
    box-shadow: 220px -29.82158px 240px rgba(255, 214, 68, 0.4), -220px -29.82158px 240px rgba(255, 214, 68, 0.4);
    border-radius: 290px;
    ${props => props.theme.device.laptopLg} {
        top: 18rem;
        box-shadow: 220px -9.82158px 240px rgba(255, 214, 68, 0.4), -220px -9.82158px 240px rgba(255, 214, 68, 0.4);
    }
`
const MaskedImage = styled.img`
    mask-image: url(${props => props.mask});
    mask-size: 100% 100%;
    position: absolute;
    left: 50%;
    top: 10rem;
    transform: translateX(-50%);
    z-index: -2;
    width: 1997px;
    height: 818px;
    ${props => props.theme.device.laptopLg} {
        top: 17rem;
    }
`
const CityContainer = styled.div`
    position: absolute;
    top: 10rem;
    z-index: -4;
    width: 100%;
    height: 818px;
    background: url(${CityPhoto}) top center repeat-x;
    background-size: 1997px 818px;
    ${props => props.theme.device.laptopLg} {
        top: 17rem;
    }
`
const Brand = styled(motion.div)`
    grid-column: col-start / span 12;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 1.5rem;
    & svg {
        width: 2rem;
    }
    ${props => props.theme.device.tablet} {
        & svg {
            width: 2.2rem;
        }
    }
    ${props => props.theme.device.laptop} {
        & svg {
            width: 3.25rem;
        }
        margin-bottom: 2.5rem;
    }
`

const Highlight = styled.span`
    color: ${props => props.theme.color.textLink};

    // make this smaller than first part of headline
    font-size: 0.6em;
    display: block;
    margin-top: 12px;
    letter-spacing: 0;
    font-weight: 600;
    line-height: 1.2;
`

const Hero = styled.div`
    position: relative;
    margin-top: 2rem;
    margin-bottom: 10rem;
    height: 900px;
    ${props => props.theme.device.tablet} {
        margin-bottom: 8rem;
        margin-top: 4rem;
    }
    ${props => props.theme.device.laptop} {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
`
const Border = styled.div``
const VideoContainer = styled.div`
    position: static;
    margin: 0 auto;
    top: 0px;
    border-radius: 15px;
    width: 315px;
    ${props => props.theme.device.laptopLg} {
        position: absolute;
        right: -130px;
        top: 110px;
    }
    & ${Border} {
        position: absolute;
        top: 0;
    }
    & video {
        position: relative;
        top: 13px;
        border-radius: 10px;
    }
`
const CopilotUI = styled.div`
    position: relative;
    margin: 0 auto;
    z-index: -1;
    text-align: center;
    position: relative;
    ${props => props.theme.device.laptopLg} {
        width: 1200px;
    }
`
const UICover = styled.div`
    position: absolute;
    top: 12rem;
    width: 100%;
    height: 818px;
    background: url(${Cover}) top center no-repeat;
    background-size: 1997px 818.5px;
    ${props => props.theme.device.laptopLg} {
        top: 17rem;
    }
`
const DownloadButton = styled.div`
    display: block;
    grid-column: col-start / span 12;
    margin: 0 auto;
    text-align: center;
    margin: 2rem 0 0.5rem 0;
    & a {
        padding: 8px 20px;
    }
    ${props => props.theme.device.laptopLg} {
        margin: 2rem 0;
    }
`
const PlatformCallout = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    margin-bottom: 1.3rem;
    justify-content: center;
`
const PlatformLabel = styled.p`
    font-weight: 600;
    font-size: 1rem;
    line-height: 16px;
    background: linear-gradient(270deg, rgba(43, 182, 26, 0) 2.67%, rgba(43, 182, 26, 0.7) 54.52%, rgba(43, 182, 26, 0) 113%), #DFC942;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
`

const ArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path fill="#6aa7f847"
       d="M9.96649 18.5046C14.6373 18.5046 18.5044 14.6291 18.5044 9.96664C18.5044 5.2959 14.6289 1.42871 9.95813 1.42871C5.29574 1.42871 1.42856 5.2959 1.42856 9.96664C1.42856 14.6291 5.30412 18.5046 9.96649 18.5046Z"/>
      <path fill="url(#paint0_linear_4218_9009)"
      d="M14.4029 9.95829C14.4029 10.1508 14.3276 10.3015 14.1601 10.4605L11.2472 13.3819C11.13 13.499 10.9794 13.566 10.7952 13.566C10.4353 13.566 10.1674 13.2898 10.1674 12.9299C10.1674 12.7457 10.2427 12.5866 10.3599 12.4694L11.4314 11.4064L12.4777 10.5359L10.6529 10.6029H6.1663C5.78963 10.6029 5.5134 10.335 5.5134 9.95829C5.5134 9.57321 5.78963 9.30536 6.1663 9.30536H10.6529L12.4777 9.38071L11.4314 8.51019L10.3599 7.44713C10.2511 7.32157 10.1674 7.16253 10.1674 6.97838C10.1674 6.61844 10.4353 6.35059 10.7952 6.35059C10.9794 6.35059 11.13 6.40919 11.2472 6.52637L14.1601 9.45607C14.3359 9.63186 14.4029 9.76579 14.4029 9.95829Z"/>
      <defs>
        <linearGradient id="paint0_linear_4218_9009" x1="7.96652" y1="6.04262" x2="7.96652" y2="9.89084" gradientUnits="userSpaceOnUse">
          <stop stop-color="#6aa7f8" stop-opacity="1"/>
          <stop offset="1" stop-color="#6aa7f8" stop-opacity="1"/>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Intro
