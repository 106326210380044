import styled, { css } from "styled-components";

import IconCommandF from "./icon-cmd-f";
import IconFaceID from "./icon-faceid";
import { LightAndDarkMode } from "./light-and-dark-mode";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import cssOutlineMixin from "../../shared/css-outline-mixin";

const Primitive = ({ title, subtitle, AccessoryElement, bgStyle, children, gridRules, ...props }) => {
  return (
    <Base
      bgStyle={bgStyle}
      gridRules={gridRules} 
      {...props}
    >
      <Header>
        <div>
          <h6>{title}</h6>
          <h6 className="muted">{subtitle}</h6>
        </div>
        {AccessoryElement && <div><AccessoryElement/></div>}
      </Header>
      <Content>
        {children}
      </Content>
    </Base>
  )
}


const macAndiPhoneBG = css`
  background: linear-gradient(90deg, #991D41 23%, #5D0FAE 90%);
  background-image: linear-gradient(${props => props.direction}, color(display-p3 0.605 0.082 0.309), color(display-p3 0.354 0 0.708));
`
const macBG = css`
  background-color: #991D41;
  color(display-p3 0.605 0.082 0.309);
`
const iPhoneBG = css`
  background-color: #5D0FAE;
  background-color: color(display-p3 0.286 0 0.708);
`

const Content = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 83px);
  display: block;
`

const Base = styled.div`
  overflow: hidden;
  grid-column: ${props => props.isTwoCol ? 'span 2' : 'span 1'};
  grid-row: ${props => props.isTwoRow ? 'span 2' : 'span 1'};
  border-radius: 10px;
  ${props => props.bgStyle.bg};
  ${props => props.gridRules};
  ${cssOutlineMixin};
  &:after {
    // box-shadow: inset 0px 1.5px 0px ${props => props.theme.color.textPrimary}, inset 0px -1px 0px ${props => props.theme.color.textPrimary};
    border-bottom: 0.5px solid ${props => props.theme.color.textPrimary};
  }
`
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 83px;
  padding: 18px 20px 0 20px;
  & h6 {
    font-size: 12pt;
    font-weight: 600;
    // text-transform: uppercase;
  }
  & h6.muted {
    text-transform: none;
    // color: ${props => props.theme.color.textSecondary};
    color: #D1416B;
    mix-blend-mode: screen;
    mix-blend-mode: plus-lighter;
  }
`

export const BulkActions = () => {
  const gridRules = css`
    grid-area: 6 / 1 / span 1 / span 1;
    ${props => props.theme.device.tablet} {
      grid-area: 4 / 1 / span 1 / span 1;
    }
    ${props => props.theme.device.laptopLg} {
      grid-area: 1 / 1 / span 1 / span 1;
    }
  `
  return (
    <Primitive
      title="Bulk actions"
      subtitle="Mac"
      bgStyle={{ bg: macBG }}
      gridRules={gridRules}
    >
      <ImageAssets>
          <StaticImage
            src={"./images/bulk-actions-list.png"}
            layout="fullWidth"
            quality={100} 
            placeholder="none"
            alt=""
          />
          <StaticImage
            src={"./images/bulk-actions.png"}
            width={400}
            quality={100} 
            placeholder="none"
            className="shift"
            alt=""
          />
      </ImageAssets>
    </Primitive>
  )
}

export const CashFlow = () => {
  const gridRules = css`
    grid-area: 7 / 1 / span 1 / span 1;
    ${props => props.theme.device.tablet} {
      grid-area: 4 / 2 / span 1 / span 1;
    }
    ${props => props.theme.device.laptopLg} {
      grid-area: 1 / 2 / span 1 / span 1;
    }
  `
  
  return (
    <Primitive
      title="Cash flow"
      subtitle="Mac and iPhone"
      bgStyle={{ bg: macBG }}
      gridRules={gridRules}
    >
      <CashFlowImage>
          <StaticImage
            src={"./images/cashflow.png"}
            layout="fullWidth"
            quality={100} 
            placeholder="none"
            alt=""
          />
      </CashFlowImage>
    </Primitive>
  )
}

export const ColorModes = () => {
  const gridRules = css`
    grid-area: 1 / 1 / span 2 / span 1;
    ${props => props.theme.device.tablet} {
      grid-area: 1 / 1 / span 2 / span 1;
    }
    ${props => props.theme.device.laptopLg} {
      grid-area: 1 / 3 / span 2 / span 1;
    }
  `
  return (
    <Primitive
      title="Light and Dark mode"
      subtitle="Mac and iPhone"
      bgStyle={{ bg: macAndiPhoneBG, direction: "to right" }}
      gridRules={gridRules}
    >
      <LightAndDarkMode/>
    </Primitive>
  )
}

export const Widgets = () => {
  const gridRules = css`
    grid-area: 3 / 1 / span 2 / span 1;
    ${props => props.theme.device.tablet} {
      grid-area: 1 / 2 / span 2 / span 1;
    }
    ${props => props.theme.device.laptopLg} {
      grid-area: 1 / 4 / span 2 / span 1;
    }
  `
  return (
    <Primitive
      title="Widgets"
      subtitle="iPhone"
      bgStyle={{ bg: iPhoneBG }}
      gridRules={gridRules}
    >
      <WidgetImageContainer>
        <WidgetImage>
          <StaticImage
            layout="constrained"
            loading="eager"
            width={279} 
            src="./images/widgets.png"
            alt=""
            placeholder="none"
          />
        </WidgetImage>
      </WidgetImageContainer>
    </Primitive>
  )
}

const WidgetImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
`
const WidgetImageContainer = styled.div`
    margin: 0 auto;
    text-align: center;
    position: relative;
    width: 279px;
`

export const QuickLauncher = () => {
  const gridRules = css`
    grid-area: 5 / 1 / span 1 / span 1;
    ${props => props.theme.device.tablet} {
      grid-area: 3 / 1 / span 1 / span 2;
    }
    ${props => props.theme.device.laptopLg} {
      grid-area: 2 / 1 / span 1 / span 2;
    }
  `
  const AccessoryEl = () => <div style={{zIndex: 1}}><IconCommandF/></div>
  return (
    <Primitive
      title="Quick launcher"
      subtitle="Mac"
      bgStyle={{ bg: macBG }}
      gridRules={gridRules}
      AccessoryElement={AccessoryEl}
    >
      <QuickLauncherContainer>
        <QuickLaunchCategories>
          <StaticImage
            layout="constrained"
            loading="eager"
            width={126}
            quality={100}
            src="./images/category-list.png"
            alt=""
            placeholder="none"
          />
        </QuickLaunchCategories>
        <QuickLaunchImage>
          <StaticImage
            layout="constrained"
            loading="eager"
            width={618}
            quality={100}
            src="./images/quick-launcher.png"
            alt=""
            placeholder="none"
          />
        </QuickLaunchImage>
      </QuickLauncherContainer>
    </Primitive>
  )
}

const QuickLauncherContainer = styled(WidgetImageContainer)`
  width: 518px;
  margin-left: 20px;
  ${props => props.theme.device.tablet} {
      width: 618px;
      margin-left: auto;
    }
`
const QuickLaunchCategories = styled.div`
    position: absolute;
    left: 25%;
    filter: blur(4px);
    opacity: 0.4;
    top: -90px;
    ${props => props.theme.device.tablet} {
      left: 55%;
      opacity: 0.7;
      filter: blur(6px);
    }
`
const QuickLaunchImage = styled(WidgetImage)`
  box-shadow: 0px 0px 28px rgba(45,5,5,0.4);
  ${cssOutlineMixin};
  border-radius: 10px;
  background-color: rgba(255,255,255,0.86);
`

export const FastSearch = () => {
  const gridRules = css`
    grid-area: 8 / 1 / span 1 / span 1;
    ${props => props.theme.device.tablet} {
      grid-area: 5 / 1 / span 1 / span 2;
    }
    ${props => props.theme.device.laptopLg} {
      grid-area: 3 / 1 / span 1 / span 2;
    }
  `
  return (
    <Primitive
      title="Fast search"
      subtitle="Mac and iPhone"
      bgStyle={{ bg: macAndiPhoneBG, direction: "to left" }}
      gridRules={gridRules}
    >
      <QuickLauncherContainer>
        <QuickLaunchImage>
          <StaticImage
            layout="constrained"
            loading="eager"
            width={618} 
            src="./images/fast-search.png"
            alt=""
            placeholder="none"
          />
        </QuickLaunchImage>
      </QuickLauncherContainer>
    </Primitive>
  )
}

export const FaceID = () => {
  const gridRules = css`
    grid-area: 9 / 1 / span 1 / span 1;
    ${props => props.theme.device.tablet} {
      grid-area: 6 / 1 / span 1 / span 1;
    }
    ${props => props.theme.device.laptopLg} {
      grid-area: 3 / 3 / span 1 / span 1;
    }
  `
  return (
    <Primitive
      title="Face ID"
      subtitle="iPhone"
      bgStyle={{ bg: iPhoneBG }}
      gridRules={gridRules}
    >
      <div style={{ margin: "0 auto", textAlign: "center", marginTop: "1.5em" }}>
        <IconFaceID/>
      </div>
    </Primitive>
  )
}

export const AppleCard = () => {
  const gridRules = css`
    grid-area: 10 / 1 / span 1 / span 1;
    ${props => props.theme.device.tablet} {
      grid-area: 6 / 2 / span 1 / span 1;
    }
    ${props => props.theme.device.laptopLg} {
      grid-area: 3 / 4 / span 1 / span 1;
    }
  `
  return (
    <Primitive
      title="Apple Card, Apple Cash, Savings"
      subtitle="iPhone"
      bgStyle={{ bg: iPhoneBG }}
      gridRules={gridRules}
    >
      <AppleCardImage>
        <StaticImage
          src={"./images/apple-card.png"}
          layout="fullWidth"
          quality={100}
          placeholder="none"
          alt=""
        />
      </AppleCardImage>
    </Primitive>
  )
}

const ImageAssets = styled.div`
  display: grid;
  & .gatsby-image-wrapper {
    grid-area: 1 / 1;
  }
  & .shift {
    opacity: 0.95;
    top: 0;
    ${props => props.theme.device.tablet} {
      top: 22px;
    }
    ${props => props.theme.device.laptopLg} {
      top: 41px;
    }
  }
`
const CashFlowImage = styled(ImageAssets)`
  margin-top: -50px;
  top: 20px;
  margin-left: 20px;
  ${props => props.theme.device.tablet} {
    margin-top: -50px;
  }
  ${props => props.theme.device.laptopLg} {
    margin-top: -40px;
  }
`

const AppleCardImage = styled(ImageAssets)`
  margin-top: 2px;
`
